<template>
  <div>
    <v-btn class="text-none text-body-1 font-weight-medium"
           color="#de8d55"
           dark
           elevation="0"
           large
           rounded
           @click="ui.dialogSignIn = true">
      Entrar
    </v-btn>

    <v-dialog v-model="ui.dialogSignIn"
              width="500">
      <v-card class="rounded-lg" style="padding-bottom: 13px!important;">
        <validation-observer
            ref="observer"
            v-slot="{invalid}">
          <v-form @submit.prevent="submitLogin">
            <v-toolbar dense class="pt-2" flat>
              <v-spacer class="pr-8"></v-spacer>
              <h2>Bienvenido</h2>
              <v-spacer></v-spacer>
              <v-btn icon @click="ui.dialogSignIn = false">
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-toolbar>
            <p class="mb-3">Inicia sesión o regístrate</p>
            <v-card-text class="pb-1">
              <validation-provider v-slot="{errors}" rules="isValidPhoneNumber">
                <vue-tel-input-vuetify v-model="loginForm.phone"
                                       :error-messages="errors"
                                       :onlyCountries="['cu']"
                                       :valid-characters-only="true"
                                       class="myPhone"
                                       clearable
                                       color="primary"
                                       defaultCountry="cu"
                                       label="Número de Teléfono"
                                       mode="international"
                                       outlined
                                       placeholder=""
                                       required>
                </vue-tel-input-vuetify>

              </validation-provider>
              <validation-provider v-slot="{errors}"
                                   rules="required">
                <v-text-field v-model="loginForm.password"
                              :append-icon="!showEye ? 'mdi-eye-off-outline' : 'mdi-eye-outline'"
                              :error-messages="errors"
                              :type="!showEye ? 'password' : 'text'"
                              color="primary"
                              label="Contraseña"
                              outlined
                              @click:append="showEye = !showEye">
                </v-text-field>
              </validation-provider>
            </v-card-text>
            <v-card-actions class="px-4">
              <v-btn :loading="loginForm.loading"
                     block
                     class="text-none text-body-1 font-weight-medium"
                     color="primary"
                     large
                     rounded
                     type="submit">
                Iniciar sesión
              </v-btn>
            </v-card-actions>
            <!--<p style="color: grey">¿Olvidaste la contraseña?</p>-->
          </v-form>
        </validation-observer>
        <sign-up class="mt-1" componentName="RegisterSignIn"></sign-up>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import {required} from 'vee-validate/dist/rules'
import {extend, setInteractionMode, ValidationObserver, ValidationProvider} from 'vee-validate'
import {isValidPhoneNumber} from 'libphonenumber-js'

const SignUp = () => import(/* webpackPrefetch: true */ "@/components/SignUp");

setInteractionMode('eager')

extend('required', {
  ...required,
  message: 'El campo no puede estar vacio.',
})
extend('isValidPhoneNumber', {
  ...required,
  message: 'El número de teléfono no es válido.',
  validate: function (val) {
    return isValidPhoneNumber(val);
  }
})

export default {
  name: "SignIn",
  components: {
    ValidationProvider,
    ValidationObserver,
    SignUp
  },
  data: () => ({
    ui: {
      dialogSignIn: false,
    },
    showEye: false,
    dialog1: false,
    loginForm: {
      phone: '',
      validPhoneNumber: true,
      password: '',
      loading: false,
    },
  }),
  methods: {

    /**
     * Login into the system.
     * @returns {Promise<void>}
     */
    submitLogin: async function () {
      const valResult = await this.$refs.observer.validate();
      if (!valResult)
        return;

      try {
        this.loginForm.loading = true;
        let response = await this.$store.dispatch('auth/login', {
          phone: this.cleanPhoneNumber(this.loginForm.phone),
          password: this.loginForm.password
        });
        if (response.user.id) {
          if (response.business.length === 0) {
            await this.$router.push({name: 'admin_welcome'});
          } else {
            await this.$router.push({name: 'admin_business_edit_business', params: {slug: response.business[0].slug}});
          }
        }
      } catch (error) {
        console.log(error);
        this.$dialog.notify.error('Credenciales incorrectas.', {
          position: 'bottom-right',
          timeout: 5000,
        })
      } finally {
        this.loginForm.loading = false;
      }
    },

    cleanPhoneNumber: function (phoneNumber) {
      phoneNumber = this.replaceAll(phoneNumber, ' ', '');
      phoneNumber = this.replaceAll(phoneNumber, '(', '');
      phoneNumber = this.replaceAll(phoneNumber, ')', '');
      phoneNumber = this.replaceAll(phoneNumber, '-', '');
      phoneNumber = phoneNumber.trim();
      return phoneNumber;
    },
    replaceAll: function (string, search, replace) {
      return string.split(search).join(replace)
    }
  }
}
</script>

<style scoped>
.v-card {
  text-align: center !important;
  padding: 0px 10px 30px 10px !important;
}

</style>
<style>

.myPhone > .country-code {
  margin-right: 10px;
}
</style>
